import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ArtistItem from './ArtistItem'

export default class ArtistsPage extends React.Component {
  render() {
    const { posts } = this.props

    return (
      <section className="collection section">
        <div className="container">
          <div className="columns is-multiline grid-offset">
          {posts.map(({ node: post }) => (
            <div
              className="grid-offset__item artwork column is-paddingless is-6-tablet is-4-desktop"
              key={post.id}
            >
              <ArtistItem artist={post} />
            </div>
          ))}
          </div>
        </div>
      </section>
    )
  }
}

ArtistsPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ArtistListFields on wordpress__wp_artists {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    featured_media {
      localFile {
        childImageSharp {
            # Try editing the "maxWidth" value to generate resized images.
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
          }
      }
    }
    acf {
      country
    }
  }
`
