import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ArtistList from '../components/ArtistList'
import SectionHead from '../components/SectionHead'
import ArtistsAZ from '../components/ArtistsAZ'
import Pagination from '../components/Pagination'
import BackToTop from '../components/BackToTop'

export default class ArtistsPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressWpArtists

    return (
      <section>
        <Helmet title={`Artists | Q Contemporary`} />
        <SectionHead title="Collection Highlights" />
        <ArtistsAZ posts={posts} title="A–Z" />
        <ArtistList posts={posts} title="Artists" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
        <BackToTop />
      </section>
    )
  }
}

ArtistsPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpArtists: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  })
}

export const pageQuery = graphql`
  query ArtistsQuery {
    allWordpressWpArtists(
      sort: { fields: acf___last_name, order: ASC }
    ) {
      edges {
        node {
          ...ArtistListFields
          ...ArtistsAZFields
        }
      }
    }
  }
`
