import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Reveal from 'react-reveal/Reveal';


export default class ArtistItem extends React.Component {
    render() {
        const { artist } = this.props
        return(
            <div className="artist-item column is-10" id={artist.title}>
                { artist.featured_media.localFile ?
                    <Reveal effect="fadeInUp">
                        <Link className="has-text-primary" to={ "/collection/" + artist.slug}>
                            { artist.featured_media.localFile.childImageSharp ?
                                <Img style={{ width: '100%'}} fluid={artist.featured_media.localFile.childImageSharp.fluid} key={artist.featured_media.id}/>
                            : false }
                            <div className="artist-item__text">
                                {artist.title}<br/>
                                {artist.acf.country}
                            </div>
                        </Link>
                    </Reveal>
                : false }
            </div>
        )
    }
}

ArtistItem.propTypes = {
    artist: PropTypes.object,
}