import React from 'react'
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";


export default class Revealer extends React.Component {
    render() {
        const { children, className } = this.props
        return(
            <VisibilitySensor partialVisibility={true} offset={{top:10}}>
                {({ isVisible }) => (
                <Spring to={{ opacity: isVisible ? 1 : 0 }}>
                    {({ opacity }) => 
                        <div className={className} style={{ opacity }}>
                            { children }
                        </div>
                    }
                </Spring>
                )}
            </VisibilitySensor>
        )
    }
}