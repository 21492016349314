import React from 'react'

export default class BackToTop extends React.Component {
    
    componentDidMount() {
        this.prev = window.scrollY;
        window.addEventListener('scroll', this.onScroll, false);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    onScroll = (e) => { 
        this.handleNavigation(e);
    }

    handleNavigation = (e) => {
        const window = e.currentTarget;

        if (this.prev > window.scrollY + 20) {
            document.getElementById('btt').classList.add('hidden');
        }
        else if(this.prev < window.scrollY){
            document.getElementById('btt').classList.remove('hidden');
        }
        this.prev = window.scrollY;
    };

    render() {
        return(
            <div id="btt" className="backtotop hidden">
                <a href="#top">back to top</a>
            </div>
        )
    }
}