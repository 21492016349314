import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Revealer from './Revealer'


export default class ArtistItemAZ extends React.Component {
    render() {
        const { artist } = this.props
        return(
            <Revealer>
                <Link className="has-text-primary" to={ "/collection/" + artist.slug}>
                    <div className="artists-az-item">
                        {artist.title}
                    </div>
                </Link>
            </Revealer>
        )
    }
}

ArtistItemAZ.propTypes = {
    artist: PropTypes.object,
}