import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ArtistItemAZ from './ArtistItemAZ'
import Reveal from 'react-reveal/Reveal';

export default class ArtistsAZ extends React.Component {
    
    render() {
        const { posts, title } = this.props

        return (
            <section>
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-paddingless is-12-tablet is-4-desktop">
                            <Reveal effect="fadeInUpSlow">
                                <h2 className="column margin-top-0">{title}</h2>
                            </Reveal>
                        </div>
                        <div className="artists-az column is-11-mobile is-11-tablet is-8-desktop">
                                <Reveal effect="fadeInUpSlow">
                                    {posts.map(({ node: post }) => (
                                        <div
                                            className="margin-top-0 padding-top-0 padding-bottom-0 is-mobile is-6-mobile is-4-tablet is-3-desktop"
                                            key={post.id}
                                        >
                                            <ArtistItemAZ artist={post} />
                                        </div>
                                    ))}
                                </Reveal>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

ArtistsAZ.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ArtistsAZFields on wordpress__wp_artists {
    id
    title
    acf {
        last_name
    }
  }
`
